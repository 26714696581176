import React from "react";

const ProductImage = ({ displayImg, title, classes }) => {
  return (
    <div className="aspect-w-1 aspect-h-1 rounded-lg overflow-hidden">
      {displayImg && (
        <img
          src={displayImg}
          alt={title}
          className={`w-full h-full object-center object-cover ${classes.productImgDisplay}`}
        />
      )}
    </div>
  );
};

export default ProductImage;
